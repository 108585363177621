import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenStorage } from '../../../../../../core/auth/token-storage.service';
import { ApiEndpointConstants } from '../../../../../../core/models/api-endpoint-constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class EconomyService {

  private economy_overview_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.ECONOMY_OVERVIEW_LIST;
  private economy_salary_period_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.ECONOMY_SALARY_PERIOD_LIST;
  private economy_salary_period_prodwise_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.ECONOMY_SALARY_PERIOD_PRODWISE_LIST;
  private economy_salary_period_prodwise_url_profile = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.ECONOMY_SALARY_PERIOD_PROFILE;
  private economy_update_actual_revenue_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.ECONOMY_ACTUAL_REVENUE_UPDATE;
  private economy_management_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.ECONOMY_MANAGEMENT_SALARY;
  private economy_management_update_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.ECONOMY_MANAGEMENT_SALARY_UPDATE;
  private economy_summary_update_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.UPDATE_ECONOMY_MONTHLY_SUMMARY_DETAILS;
  private monthly_salary_report_download = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.MONTHLY_SALARY_REPORT_DOWNLOAD;
  private monthly_bonus_summary_api_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYEE_BONUS_MONTHLY;
  constructor(private http: HttpClient, private tokenStorage: TokenStorage) { }

  getRoleAndId(): Observable<any> {
    let userData: any = {};
    userData.role = this.tokenStorage.getUserRoles().pipe(role => role);
    userData.userId = this.tokenStorage.getUserId().pipe(id => id);
    return userData;
  }

  getEconomyOverviewList(options: any): Observable<Array<any>> {
    return this.http.post(this.economy_overview_url, options).pipe(map((res: any) => res));
  }

  getAllEconomySalaryPeriod(options: any): Observable<any> {
    return this.http.post(this.economy_salary_period_url, options).pipe(map((res: any) => res));
  }

  getAllEconomySalaryPeriodReportProductwise(options: any): Observable<Array<any>> {
    return this.http.post(this.economy_salary_period_prodwise_url, options).pipe(map((res: any) => res));
  }

  getAllEconomySalaryPeriodReportProductwiseForProfile(options: any): Observable<Array<any>> {
    return this.http.post(this.economy_salary_period_prodwise_url_profile, options).pipe(map((res: any) => res));
  }

  updateActualRevenue(revenueData: any): Observable<any> {
    return this.http.post(`${this.economy_update_actual_revenue_url}`, revenueData).pipe(map((res: any) => res));
  }

  getAllEconomyManagement(options: any): Observable<Array<any>> {
    return this.http.post(this.economy_management_url, options).pipe(map((res: any) => res));
  }

  updateEconomyManagement(revenueData: any): Observable<any> {
    return this.http.post(`${this.economy_management_update_url}`, revenueData).pipe(map((res: any) => res));
  }

  updateEconomySummaryDetails(economySummaryData: any): Observable<any> {
    return this.http.post(`${this.economy_summary_update_url}`, economySummaryData).pipe(map((res: any) => res));
  }

  monthlySalaryReportDownload(options: any): Observable<any> {
    return this.http.post(`${this.monthly_salary_report_download}`, options).pipe(map((res: any) => res));
  }

  summaryMonthlyBonus(data: any): Observable<any> {
    return this.http.post(`${this.monthly_bonus_summary_api_url}`, data).pipe(map((res: any) => res));
  }

}
