import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiEndpointConstants } from '../../../../../../core/models/api-endpoint-constants';
import { map } from 'rxjs/operators';

@Injectable()
export class SystemSettingService {

    private company_schedule_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.COMPANY_CALENDAR_SCHEDULE;
    private company_monthly_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.COMPANY_CALENDAR;
    private company_schedule_add = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.ADD_COMPANY_CALENDAR;
    private company_info_update = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.COMPANY_CALENDAR_INFO_UPDATE;
    private department_schedule_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.DEPARTMENT_CALENDAR_SCHEDULE;
    private department_monthly_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.DEPARTMENT_CALENDAR;
    private department_schedule_add = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.ADD_DEPARTMENT_CALENDAR;
    private department_info_update = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.DEPARTMENT_CALENDAR_INFO_UPDATE;
    private company_leave_delete = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.DELETE_LEAVE;
    private employee_personal_schedule = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYE_PERSONAL_SCHEDULE;
    private company_leave_history = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.COMPANY_LEAVE_HISTORY;
    private company_leave_update = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.COMPANY_LEAVE_UPDATE;
    private department_schedule_history = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.DEPARTMENT_SCHEDULE_HISTORY;
    private department_schedule_history_update = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.DEPARTMENT_SCHEDULE_HISTORY_UPDATE;
    private department_schedule_by_group = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.GET_DEPARTMENT_HISTORY_BY_GROUP;
    private notification_send = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.SEND_CALENDAR_NOTFICIATION;
    private employee_new_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYEE_NEW;
    private noticer_list_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.NOTICER_LIST;
    private add_noticer_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.ADD_NOTICER;

    constructor(private http: HttpClient) { }

    getCompanySchedule(): Observable<any> {
        return this.http.get(this.company_schedule_url).pipe(map((res: any) => res));
    }

    saveCompanySchedule(scheduleData): Observable<any> {
        return this.http.post(this.company_schedule_url, scheduleData).pipe(map((res: any) => res));
    }

    addCompanySchedule(scheduleData): Observable<any> {
        return this.http.post(this.company_schedule_add, scheduleData).pipe(map((res: any) => res));
    }

    calendarSchedule(scheduleData): Observable<any> {
        return this.http.post(this.company_info_update, scheduleData).pipe(map((res: any) => res));
    }

    calendarMonthly(data): Observable<any> {
        return this.http.post(this.company_monthly_url, data).pipe(map((res: any) => res));
    }

    getDepartmentSchedule(): Observable<any> {
        return this.http.get(this.department_schedule_url).pipe(map((res: any) => res));
    }

    saveDepartmentSchedule(scheduleData, id): Observable<any> {
        return this.http.post(this.department_schedule_url + "/" + id, scheduleData).pipe(map((res: any) => res));
    }

    addDepartmentSchedule(scheduleData): Observable<any> {
        return this.http.post(this.department_schedule_add, scheduleData).pipe(map((res: any) => res));
    }

    DepartmentcalendarSchedule(scheduleData, id): Observable<any> {
        return this.http.post(this.department_info_update + "/" + id, scheduleData).pipe(map((res: any) => res));
    }

    DepartmentcalendarMonthly(data, id): Observable<any> {
        return this.http.post(this.department_monthly_url + "/" + id, data).pipe(map((res: any) => res));
    }

    DeleteLeaveById(data, leaveID): Observable<any> {
        return this.http.delete(this.company_leave_delete + "/" + leaveID, data).pipe(map((res: any) => res));
    }

    EmployeePersonalSchedule(data, id): Observable<any> {
        return this.http.post(this.employee_personal_schedule + "/" + id, data).pipe(map((res: any) => res));
    }

    saveLeaveByDepartment(data, departmentId): Observable<any> {
        return this.http.post(this.company_leave_update, data).pipe(map((res: any) => res));
    }

    getCompanyLeaveHistoryList(): Observable<any> {
        return this.http.get(`${this.company_leave_history}`).pipe(map((res: any) => res));
    }

    getDepartmentScheduleHistory(): Observable<any> {
        return this.http.get(`${this.department_schedule_history}`).pipe(map((res: any) => res));
    }

    getDepartmentScheduleByGroupId(groupId): Observable<any> {
        return this.http.get(`${this.department_schedule_by_group}/${groupId}`).pipe(map((res: any) => res));
    }

    updateDepartmentScheduleHistory(departmentScheduleData): Observable<any> {
        return this.http.post(`${this.department_schedule_history_update}`, departmentScheduleData).pipe(map((res: any) => res));
    }

    notifyLeaveToUsers(groupId): Observable<any> {
        return this.http.get(`${this.notification_send}/${groupId}`).pipe(map((res: any) => res));
    }

    getEmployeesByRole(roleId: any): Observable<any> {
        return this.http.get(`${this.employee_new_url}/GetEmployeesByRole/${roleId}`).pipe(map((res: any) => res));
    }

    getMenusByRole(query: any): Observable<any> {
        return this.http.post(`${this.employee_new_url}/GetMenusByRole`, query).pipe(map((res: any) => res));
    }

    saveMenuPermissions(data: any): Observable<any> {
        return this.http.post(`${this.employee_new_url}/menupermission`, data).pipe(map((res: any) => res));
    }

    getExtraMenuPermissions(data: any): Observable<any> {
        return this.http.post(`${this.employee_new_url}/GetExtraMenuPermission`, data).pipe(map((res: any) => res));
    }

    getNoticerList(): Observable<any> {
        return this.http.get(`${this.noticer_list_url}`).pipe(map((res: any) => res));
    }

    addNoticer(data: any): Observable<any> {
        return this.http.post(`${this.add_noticer_url}`, data).pipe(map((res: any) => res));
    }
}
