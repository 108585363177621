import { Injectable, Injector } from '@angular/core';
import { PagesModule } from '../../content/pages/pages.module';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TokenStorage } from '../auth/token-storage.service';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class CommonInterceptService implements HttpInterceptor{

  private authService:AuthenticationService;
  constructor(private injector: Injector,private tokenStorage: TokenStorage) {
    this.authService = injector.get(AuthenticationService);
   }

  intercept(req:HttpRequest<any>,next:HttpHandler):Observable<HttpEvent<any>>{
    let userAccessToken;
		this.authService.getAccessToken().subscribe((token)=>{
			userAccessToken = token;
		});
		if(userAccessToken){
			req = req.clone({
        setHeaders: this.authService.getHeaders(userAccessToken)
			});
		}
    
    return next.handle(req).pipe(
      tap(
				event => {
          if (event instanceof HttpResponse) {
						// http response status code
          }
        },
				error => {
          // this.tokenStorage.clear();
          // location.reload(true);
        }
      )
    );
  }
}
